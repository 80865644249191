import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { graphql } from "gatsby"


const Mentions = ({data}) => {

  const {edges} = data.allMarkdownRemark
  const { site } = data 
  const { markdownRemark } = data 
  const { siteMetadata } = site 
  const { html } = markdownRemark

  return(
    <Layout>
      <SEO title="Contact" />
      {edges.map(edge => (
        edge.node.frontmatter.templateKey === "home" && 
          <section className="hero is-fullheight is-home" style={{backgroundImage: `url(${edge.node.frontmatter.photoHeader})`}}>
            <div className="hero-header">
                <Header siteTitle={siteMetadata.title} />
            </div>
            <div className="hero-body">
              <div className="container has-text-centered">
                <div className="columns is-centered">
                  <div className="column is-10 is-12-tablet">
                    <h1 className="title is-size-1 has-text-white is-size-2-mobile">
                      Mentions légales 
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
      ))}

      
      
      <section className="hero is-primary is-medium" style={{borderBottom: "1px solid #FFF"}}>
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-10">
                <h1 className="title has-text-white">
                  Pour plus de renseignements : 
                </h1>

                <div className="columns is-centered">
                  <div className="column is-10">
                    <div className="columns is-centered has-text-white">
                        <div
                          className="content has-text-white mentions"
                          dangerouslySetInnerHTML={{ __html: html }}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </Layout>
  )

}

export default Mentions


export const MentionsQuery = graphql`
query Mention {
  markdownRemark(frontmatter: {templateKey: {eq: "mentions"}}) {
    html
  }
  allMarkdownRemark {
    edges {
      node {
        frontmatter {
          templateKey
          photoHeader
        }
      }
    }
  }
  site {
    siteMetadata {
      title
    }
  }
}
`

